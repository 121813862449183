<template>
    <tr>
        <td>
            <b>{{ name }}</b>
        </td>
        <td>
            <b>{{ email }}</b>
        </td>
        <td>
            <span
                v-if="isAdmin"
                class="icon has-text-primary">
                <i class="fa fa-check" />
            </span>
        </td>
        <td>
            <!-- <div class="field">
                <div class="control is-size-3">
                    <input
                        v-model="isPluginEnabled"
                        :identifier="identifier + 'pluginEnabledSwitch'"
                        type="checkbox"
                        :name="identifier + 'pluginEnabledSwitch'"
                        class="switch is-outlined">
                    <label :for="identifier + 'pluginEnabledSwitch'" />
                </div>
            </div> -->
        </td>
        <td>
            <div
                title="Manage User"
                class="button is-outlined is-small is-primary"
                @click="$emit('show-details', identifier)">
                <span class="icon">
                    <i class="fas fa-cog" />
                </span>
            </div>
            <div
                title="Delete User"
                class="button is-outlined is-small is-danger delete-btn"
                @click="$emit('show-delete', identifier)">
                <span class="icon">
                    <i class="fas fa-trash" />
                </span>
            </div>
            <div
                v-if="!isOwned"
                title="View User"
                class="button is-outlined is-small is-primary"
                @click="$emit('show-details', identifier)">
                <span class="icon">
                    <i class="fas fa-eye" />
                </span>
            </div>
        </td>
    </tr>
</template>

<script>
import {pluginUtil} from '../../mixins/pluginUtil';
export default {
    name: 'UserListItem',
    mixins: [pluginUtil],
    props: {
        name: {
            type: String,
            default: ''
        },
        email: {
            type: String,
            default: ''
        },
        identifier: {
            type: String,
            default: ''
        },
        isAdmin: {
            type: Boolean,
            default: false
        }
    }
};
</script>


<style lang="scss" scoped>
    .delete-btn {
        margin-left: .4rem;
    }
</style>