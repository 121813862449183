<template>
    <div id="plugin-manager">
        <!-- busy modal-->
        <div
            class="modal"
            :class="[{'is-active': userManagerBusy}]">
            <div class="modal-background" />
            <div class="modal-content has-text-centered">
                <span class="icon is-large has-text-center has-text-link">
                    <i class="fas fa-2x fa-spinner is-info fa-pulse" />
                </span>
            </div>
        </div>
        <!-- plugin deletion confirm modal-->
        <div
            class="modal"
            :class="[{'is-active': showConfirmDeleteUserModal}]">
            <div class="modal-background" />
            <div class="modal-card">
                <header class="modal-card-head has-background-primary">
                    <p class="modal-card-title">
                        <span class="title has-text-white">
                            Delete User?
                        </span>
                    </p>
                    <button
                        class="delete"
                        @click="cancelUserDelete"
                        aria-label="close" />
                </header>
                <section class="modal-card-body">
                    Are you sure you wish to delete the user <b>'{{ userToDelete.name }}, {{ userToDelete.email }}'</b>?
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons is-spaced">
                        <button
                            class="button is-dark is-outlined"
                            @click="cancelUserDelete">
                            Cancel
                        </button>
                        <button
                            class="button is-outlined is-primary"
                            @click="deleteUser">
                            Delete
                        </button>
                    </div>
                </footer>
            </div>
        </div>
        <!-- user management -->
        <div 
            class="container"
            v-if="userManagerBusy">    
            <div class="section">
                <p class="description">
                    Loading known users ...
                </p>
            </div>
        </div>
        <div
            class="container"
            v-if="!userManagerBusy">
            <div class="section">
                <h3 class="title is-size-1">
                    User Management
                </h3>
                <p class="description">
                    Admin users are able to view and remove known users from this instance of CaSS.
                </p>
            </div>
            <div
                class="section"
                v-if="userManagerViewMode.equals('list')">
                <h4 class="header is-size-3">
                    Users
                </h4>
                <div v-if="knownUsers.length === 0">
                    <p>No users are known, or none are available for you to modify.</p>
                </div>
                <div
                    class="table-container"
                    v-if="knownUsers.length > 0">
                    <table class="table is-hoverable is-fullwidth">
                        <thead>
                            <tr>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Email
                                </th>
                                <th title="Admistrator of this CaSS instance">
                                    Is Admin
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            <UserListItem
                                v-for="user in knownUsers"
                                :name="user.name"
                                :email="user.name"
                                :is-admin="user.isAdmin"
                                :identifier="user.identifier"
                                @enable-plugin="enablePlugin"
                                @disable-plugin="disablePlugin"
                                @show-delete="showUserDelete"
                                @show-details="showUserDetails" />
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-if="userManagerViewMode.equals('detail')">
                <UserDetails
                    :plugin="currentPlugin"
                    :readOnly="currentPluginIsReadOnly"
                    @save="saveCurrentPlugin"
                    @cancel="cancelEditCurrentPlugin"
                    @back="backFromEditCurrentPlugin" />
            </div>
        </div>
    </div>
</template>

<script>

// @ is an alias to /src
import {cassUtil} from '../../mixins/cassUtil';
import {pluginUtil} from '../../mixins/pluginUtil';
import UserListItem from '../../components/admin/UserListItem.vue';
import UserDetails from '../../components/admin/UserDetails.vue';

export default {
    mixins: [cassUtil, pluginUtil],
    props: {
        view: {
            default: '',
            type: String
        }
    },
    name: 'AdminTab',
    components: {
        UserListItem,
        UserDetails
    },
    data: () => ({
        userManagerBusy: false,
        userManagerViewMode: 'list',
        currentPlugin: {},
        currentUser: {},
        showConfirmDeleteUserModal: false,
        pluginToDelete: {},
        userToDelete: {},
        knownUsers: []
    }),
    computed: {
        currentPluginIsReadOnly: function() {
            if (!this.currentPlugin || !this.currentPlugin.isOwned) return true;
            else if (this.currentPlugin.isOwned) return false;
            else return true;
        },
        curatedPlugins: function() {
            return this.$store.getters['app/curatedPlugins'];
        }
    },
    methods: {
        showListView() {
            this.userManagerViewMode = "list";
        },
        showDetailView() {
            this.userManagerViewMode = "detail";
        },
        backFromEditCurrentPlugin() {
            this.showListView();
        },
        cancelEditCurrentPlugin() {
            this.buildManagerPluginList();
            this.showListView();
        },
        saveCurrentPlugin() {
            this.userManagerBusy = true;
            this.addLocalPlugin(this.currentPlugin.url);
            this.setPluginAsEnabled(this.currentPlugin.url);
            this.currentPlugin = {};
            this.buildManagerPluginList();
            this.showListView();
            this.$store.commit('app/pluginLastUpdate', Date.now());
        },
        async deleteUser() {
            let me = this;
            this.userManagerBusy = true;
            // this.setPluginAsDisabled(this.pluginToDelete.url);
            // this.removeLocalPlugin(this.pluginToDelete.url);
            let onSuccess = () => {
                appLog("User Deleted");
                me.$store.commit('editor/removeUser', resource);
                me.userToDelete = {};
                me.showConfirmDeleteUserModal = false;
                me.userManagerBusy = false;
                me.buildUserList();
            };
            // let onFailure = (info) => {
            //     appError(info);
            //     me.userToDelete = {};
            //     me.showConfirmDeleteUserModal = false;
            //     me.userManagerBusy = false;
            //     me.buildUserList();
            // };
            let resource = this.userToDelete.__original;
            window.repo.deleteRegistered(resource, onSuccess);
            // this.repo.delete(resource, onSuccess, onFailure);
            // this.userToDelete = {};
            // this.showConfirmDeleteUserModal = false;
            // this.buildUserList();
            // this.buildManagerPluginList();
            // this.$store.commit('app/pluginLastUpdate', Date.now());
        },
        cancelUserDelete() {
            this.userToDelete = {};
            this.showConfirmDeleteUserModal = false;
        },
        setUserToDelete(userId) {
            this.userToDelete = this.getUserById(userId);
        },
        showUserDelete(userId) {
            this.setUserToDelete(userId);
            this.showConfirmDeleteUserModal = true;
        },
        enablePlugin(pluginId) {
            this.setPluginAsEnabled(pluginId);
            this.$store.commit('app/pluginLastUpdate', Date.now());
        },
        disablePlugin(pluginId) {
            this.setPluginAsDisabled(pluginId);
            this.$store.commit('app/pluginLastUpdate', Date.now());
        },
        disableAllPlugins() {
            this.setAllPluginsAsDisabled();
            this.buildManagerPluginList();
            this.$store.commit('app/pluginLastUpdate', Date.now());
        },
        getUserById(userId) {
            for (let p of this.knownUsers) {
                if (p.identifier.equals(userId)) {
                    return p;
                }
            }
            return null;
        },
        setCurrentUser(userId) {
            this.currentUser = this.getUserById(userId);
        },
        showUserDetails(userId) {
            this.setCurrentUser(userId);
            this.showDetailView();
        },
        generateNewPluginObject() {
            let p = {};
            p.id = 'newPluginId';
            p.url = '';
            p.isCurated = false;
            p.isOwned = true;
            return p;
        },
        addNewPlugin() {
            this.currentPlugin = this.generateNewPluginObject();
            this.showDetailView();
        },
        buildPluginListFinished() {
            this.userManagerBusy = false;
        },
        buildManagerPluginList() {
            this.userManagerBusy = true;
            this.buildPluginList(this.buildPluginListFinished);
        },
        buildUserList() {
            let me = this;
            EcPerson.search(window.repo, '', success => {
                let users = [];
                for (var i = 0; i < success.length; i++) {
                    let result = success[i];
                    let pk = me.getPersonEcPk(result);
                    let person = {
                        identifier: result.shortId(), 
                        name: result.name, 
                        email: result.email, 
                        pk: pk,
                        isAdmin: me.isUserAnAdmin(pk.toPem()),
                        __original: result
                    };
                    users.push(person);
                }
                me.knownUsers = users;
            }, appError);
        }
    },
    updated() {
    },
    mounted() {
        // this.buildManagerPluginList();
        this.buildUserList();
    },
    watch: {
        curatedPlugins() {
            this.buildManagerPluginList();
        }
    }
};
</script>

<style lang="scss" scoped>
    h3 {
        font-size: 2rem;
        padding-bottom: 0rem;
    }
    h4 {
        font-size: 1.6rem;
        padding-bottom: 1rem;
    }
    h4 {
        font-size: 1.3rem;
        padding-bottom: .5rem;
    }
    .listHdr {
        font-weight: bold;
    }
</style>

