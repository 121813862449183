import { render, staticRenderFns } from "./UserGroupEditor.vue?vue&type=template&id=ee293172"
import script from "./UserGroupEditor.vue?vue&type=script&lang=js"
export * from "./UserGroupEditor.vue?vue&type=script&lang=js"
import style0 from "./UserGroupEditor.vue?vue&type=style&index=0&id=ee293172&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports